<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="BB Mali İşlemleri"
            icon="mdi-home-analytics"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
            <!-- <template slot="buttons:prepend">
              <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="BB Filtrele"
                :items="debtStatusList"
                v-model="search.debt_status"
                dense
              />
            </template> -->
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals :headers="headers" :totals="footTotals" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'incomes.houses.show',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.door_number="{ item, value }">
          <router-link
            :to="{
              name: 'incomes.houses.show',
              params: { id: item.id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.renter="{ item }">
          <router-link
            v-for="(item, i) in getRenterNames(item)"
            :key="i"
            :to="{
              name: 'incomes.residents.show',
              params: { id: item.resident_id },
            }"
          >
            {{ item.resident.name }}

            <v-tooltip bottom v-if="item.is_staying">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="float-right"
                >
                  mdi-account-circle
                </v-icon>
              </template>
              Burada oturuyor.
            </v-tooltip>
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.owner="{ item }">
          <router-link
            v-for="(item, i) in getOwnerNames(item)"
            :key="i"
            :to="{
              name: 'incomes.residents.show',
              params: { id: item.resident_id },
            }"
          >
            {{ item.resident.name }}

            <v-tooltip bottom v-if="item.is_staying">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="float-right"
                >
                  mdi-account-circle
                </v-icon>
              </template>
              Burada oturuyor.
            </v-tooltip>
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.renters_balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.owners_balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.owner_phone="{ item }">
          <rs-table-cell-phone
            :value="value.resident.phone"
            v-for="(value, i) in getOwnerNames(item)"
            :key="i"
          />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.renter_phone="{ item }">
          <rs-table-cell-phone
            :value="value.resident.phone"
            v-for="(value, i) in getRenterNames(item)"
            :key="i"
          />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.has_future_owner="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, isPage } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasExport, isPage],
  computed: {
    ...mapGetters(["blocks", "cluster", "clusterId", "houseTypes"]),
  },
  data() {
    return {
      pageMeta: {
        title: "BB Mali İşlemler Liste",
      },
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: this.$t("labels.block"),
          value: "block",
          searchable: "multiselect",
          options: () => this.blocks,
          itemText: "name",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.door_number_s"),
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.owner"),
          value: "owner",
          searchable: "text",
          width: "200px",
        },
        {
          text: this.$t("labels.owner_phone"),
          value: "owner_phone",
          searchable: "text",
        },
        {
          text: this.$t("labels.owner_balance"),
          value: "owners_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.renter"),
          value: "renter",
          searchable: "text",
          width: "200px",
        },
        {
          text: this.$t("labels.renter_phone"),
          value: "renter_phone",
          searchable: "text",
        },
        {
          text: this.$t("labels.renter_balance"),
          value: "renters_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.house_balance_s"),
          value: "balance",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.geo_block"),
          value: "geo_block",
          searchable: "text",
        },
        {
          text: this.$t("labels.geo_plot"),
          value: "geo_plot",
          searchable: "text",
        },
        {
          text: "Sözleşmeli",
          value: "has_future_owner",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: this.$t("labels.floor"),
          value: "floor",
          searchable: "text",
        },
        {
          text: this.$t("labels.house_type_s"),
          value: "house_type",
          searchable: "multiselect",
          options: () => this.houseTypes,
          itemText: "name",
          width: "85px",
        },
      ],
      search: {
        debt_status: null,
      },
      options: {
        sortBy: ["id"],
      },
      debtStatusList: [
        {
          id: null,
          name: "Tümü",
        },
        {
          id: "negative",
          name: "Borçlu",
        },
        {
          id: "positive",
          name: "Alacaklı",
        },
      ],
      titleBarAttrs: {
        exportUrl: "income/houses",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("income/houses", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getRenterNames(item) {
      const renterNames = [];

      for (const houseUser of item.house_users) {
        if (houseUser.house_user_type_id === 2) {
          renterNames.push(houseUser);
        }
      }

      return renterNames;
    },
    getOwnerNames(item) {
      const renterNames = [];

      for (const houseUser of item.house_users) {
        if (houseUser.house_user_type_id === 1) {
          renterNames.push(houseUser);
        }
      }

      return renterNames;
    },
    getBalanceClass(balance) {
      if (balance < 0) {
        return "text-danger font-weight-bold";
      } else if (balance !== 0) {
        return "text-success font-weight-bold";
      }
    },
    handleEditClick() {
      if (this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "incomes.houses.show",
        params: {
          id: this.selectedItems[0].id,
        },
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function (n, o) {
        if (JSON.stringify(o) !== JSON.stringify(n) || n.query !== undefined) {
          this.options.page = 1;
          this.setURLParams();
        }
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
